


























import Vue from 'vue'
import { mapState, mapGetters } from 'vuex'
import { RecentTrades as RecentTradesType } from './types'

export default Vue.extend({
  data(): {
    recentTrades?: RecentTradesType
    loading: boolean
  } {
    return {
      recentTrades: undefined,
      loading: true
    }
  },

  computed: {
    ...mapState('exchange', ['exchange', 'pair', 'market']),

    ...mapGetters('exchange', ['priceDecimal', 'sizeDecimal']),

    currentExchange(): string {
      if (this.exchange === 'binanceMarginCross') return 'binance'
      return this.exchange
    },

    isBitmexExchange(): boolean {
      return ['bitmex', 'bitmexTestnet'].includes(this.exchange)
    },

    underlying(): number {
      return Number(this.pair?.underlyingToPositionMultiplier || 1)
    },

    recentTradeData(): RecentTradesType | undefined {
      if (this.recentTrades) {
        if (this.isBitmexExchange && this.pair?.underlyingToPositionMultiplier) {
          return {
            ...this.recentTrades,
            data: this.recentTrades.data.map((item) => ({
              ...item,
              size: item.size / this.underlying
            }))
          }
        } else {
          return this.recentTrades
        }
      }
      return undefined
    }
  },

  methods: {
    connectSocket() {
      this.recentTrades = undefined
      this.$socket.open()

      this.$socket.on('REALTIME_TRADE', (data: RecentTradesType) => {
        if (data.exchange === this.currentExchange && data.symbol === this.pair?.symbol) {
          console.log(data)
          this.recentTrades = data
        }
      })
    }
  },

  created() {
    this.connectSocket()
  },

  watch: {
    exchange() {
      this.connectSocket()
    },

    pair: {
      handler() {
        if (!this.pair) {
          this.recentTrades = undefined
        } else {
          this.connectSocket()
        }
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.$socket.disconnect()
  }
})
